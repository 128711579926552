import { LmBlock } from './lm2/LmBlock';
import { Analysis, Reading } from '../types';
import { NitAnalysisCard } from './NitAnalysisCard/NitAnalysisCard';
import { useContext, useEffect, useState } from 'react';
import useDialog from 'hooks/useDialog';
import { INCOMING_ANALYSIS_ALERT_BODY, INCOMING_ANALYSIS_ALERT_TITLE } from 'constants/incomingAnalysisAlertStrings';
import AnalyzesContext from 'contexts/AnalyzesContext';

export const NewNitAnalysis = () => {
  const [lockedAnalysis, setLockedAnalysis] = useState<Analysis>();
  const [unlockedAnalysis, setUnlockedAnalysis] = useState<Analysis>();
  const { lastIncomingAnalysis } = useContext(AnalyzesContext);
  const { showDialog } = useDialog();

  const handleAcceptIncomingAnalysis = () => {
    setUnlockedAnalysis(lastIncomingAnalysis);
  };

  useEffect(() => {
    switch (true) {
      case !lockedAnalysis && !!unlockedAnalysis && !!lastIncomingAnalysis:
        showDialog(INCOMING_ANALYSIS_ALERT_TITLE, INCOMING_ANALYSIS_ALERT_BODY, handleAcceptIncomingAnalysis, true);
        break;
      case !lockedAnalysis && !unlockedAnalysis:
        setUnlockedAnalysis(lastIncomingAnalysis);
        break;
      default:
        mergeLockedAndIncomingAnalysis();
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastIncomingAnalysis]);

  const mergeLockedAndIncomingAnalysis = () => {
    if (!lastIncomingAnalysis) {
      return;
    }

    setLockedAnalysis(existing => {
      const lockedReadings = existing?.readings?.filter(({ locked }) => locked);
      const incomingReadings =
        lastIncomingAnalysis.readings?.map(reading => ({
          ...reading,
        })) ?? [];

      if (existing?.sampleId !== lastIncomingAnalysis?.sampleId) {
        return existing;
      }

      const mergedReadings = !lockedReadings?.length
        ? incomingReadings
        : [
            ...(lockedReadings || []),
            ...incomingReadings.filter(
              incomingReading => !lockedReadings.some(lockedReading => lockedReading.code === incomingReading.code)
            ),
          ];

      return {
        ...existing,
        readings: mergedReadings,
      };
    });
  };

  const handleLock = (locked: boolean) => {
    if (!locked && lockedAnalysis) {
      setUnlockedAnalysis(lockedAnalysis);
      setLockedAnalysis(undefined);
    } else if (locked && !lockedAnalysis && lastIncomingAnalysis) {
      const readings = unlockedAnalysis?.readings;
      const incomingReadings =
        readings?.map(reading => ({
          ...reading,
        })) ?? [];
      setLockedAnalysis({
        ...unlockedAnalysis,
        id: lastIncomingAnalysis.id,
        readings: incomingReadings.map(incomingReading => ({
          ...incomingReading,
          locked: !incomingReading.outliers?.some(outlier => outlier.value > 0),
        })),
      });
      setUnlockedAnalysis(undefined);
    }
  };

  const handleLockReading = (changedReading: Reading, locked: boolean) => {
    setLockedAnalysis(existing => {
      if (!existing) {
        return;
      }

      return {
        ...existing,
        readings:
          existing.readings?.map(reading =>
            reading.code === changedReading.code
              ? {
                  ...reading,
                  locked: locked || undefined,
                }
              : reading
          ) ?? [],
      };
    });
  };

  const clearAnalysis = () => {
    setLockedAnalysis(undefined);
    setUnlockedAnalysis(undefined);
  };

  const analysis = unlockedAnalysis || lockedAnalysis;

  return (
    <LmBlock>
        <NitAnalysisCard
          analysis={analysis}
          clearAnalysis={clearAnalysis}
          onLock={handleLock}
          locked={!!lockedAnalysis}
          onLockReading={handleLockReading}
        />
    </LmBlock>
  );
};
