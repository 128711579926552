import { DeviceType } from 'constants/deviceTypes';
import { FC } from 'react';

import styled from '@emotion/styled';
import { colors } from 'components/shared/styles/colors';

const StyledManualNitMessage = styled.div({
  display: 'flex',
  justifyContent: 'center',
  color: colors.red.primary,
});

export const ManualNitMessage: FC<{ deviceType?: string }> = ({ deviceType }) => {
  if (deviceType !== DeviceType.NIT) {
    return null;
  }

  return (
    <StyledManualNitMessage className="manual-nit-message">
      <b>Obs! Vid omanalys av NIT-parameter, måste övriga resultat fyllas i igen innan man godkänner.</b>
    </StyledManualNitMessage>
  );
};
