import { FC } from 'react';
import { Link } from 'react-router-dom';
import { LmButtonGreen } from './lm2/LmButton';
import styled from '@emotion/styled';
import { Alert, Paper } from '@mui/material';

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

const CenteredBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;

  a {
    margin-top: auto;
  }
`;

export const NotFound: FC = () => {
  return (
    <CenteredContainer>
      <Paper elevation={1} style={{  minHeight: '200px' }}>
        <Alert severity="error">
          <h1>404 - Page Not Found</h1>
        </Alert>
        <CenteredBlock>
          <p>Sidan du försökt gå till finns inte</p>
          <Link to="/">
            <LmButtonGreen>Gå till startsidan</LmButtonGreen>
          </Link>
        </CenteredBlock>
      </Paper>
    </CenteredContainer>
  );
};