import { DeviceType } from 'constants/deviceTypes';
import { Reading } from '../../types';
import { isGrossNetAmountCorrect } from 'functions/isGrossNetAmountCorrect';
import { GrossNetReading } from 'types/grossNetReading';

export const useManualAnalysisUtil = () => {

  /**
   * Check if the NIT readings has missing values
   * @param deviceType
   * @param readings - readings from support table
   * @param sampleReadings - readings from sample (needed to check if the reading is required)
   * @returns boolean - true if there are missing values that are required, false otherwise
   */
  const nitReadingsHasMissingValues = (
    deviceType: DeviceType | undefined,
    readings: Reading[],
    sampleReadings?: Reading[]
  ) => {
    if (!sampleReadings) {
      return true;
    }

    if (deviceType !== DeviceType.NIT) {
      return false;
    }

    const hasError = readings.some(reading => {
      const sampleReading = sampleReadings.find(x => x.code === reading.code);
      return sampleReading?.required && reading.value === undefined;
    });

    return hasError;
  };

  /**
   * Check if the grossAndNet has an error
   * @param grossAndNet
   * @returns boolean
   * */
  const isGrossNetError = (grossAndNet?: GrossNetReading) => {
    return (
      !isGrossNetAmountCorrect(grossAndNet?.gross?.value, grossAndNet?.net?.value) &&
      grossAndNet?.net?.value !== undefined &&
      grossAndNet?.gross?.value !== undefined
    );
  };

  /**
   * Check if the gross and net values are correct
   * @param grossAndNet
   * @returns boolean
   * */
  const isGrossNetCorrect = (grossAndNet?: GrossNetReading) => {
    /* Check if theres no gross and net values to allow to send only extra analyzes */
    if (!grossAndNet?.gross?.value && !grossAndNet?.net?.value) {
      return true;
    }
    return isGrossNetAmountCorrect(grossAndNet?.gross?.value, grossAndNet?.net?.value);
  };

  /**
   * Check if the ref test weight is valid
   * @param deviceType
   * @param readings
   * @returns boolean
   * */
  const isRefTestWeightValid = (deviceType: DeviceType | undefined, readings: Reading[]): boolean => {
    if (deviceType !== DeviceType.REF_TEST_WEIGHT) {
      return true;
    }
    const reading = readings[0];

    if (reading?.value === undefined) {
      return true;
    }

    return reading.value >= 0 && reading.value <= 9999.9;
  };
  return { isRefTestWeightValid, isGrossNetCorrect, isGrossNetError, nitReadingsHasMissingValues };
};
