import { IDevice } from '../types';

/**
 * Type guard for IDevice
 * @param item - The item to check
 * @returns True if the item is an IDevice
 * @example
 * if (isDevice(item)) {
 *  // item is an IDevice
 * }
*/
export const isDevice = (item: unknown): item is IDevice => {
  return typeof item === 'object' &&
    item !== null &&
    'id' in item &&
    'division' in item &&
    'deviceType' in item &&
    'deviceModel' in item &&
    'location' in item &&
    'alias' in item;
};

/**
 * Type guard for an array
 * @param data - The data to check
 * @returns True if the data is an array
 * @example
 * if (isArray(data)) {
 *  // data is an array
 * }
*/
export const isArray = <T>(data: unknown): data is T[] => {
  return Array.isArray(data);
};

/**
 * Type guard for an array of IDevice
 * @param data - The data to check
 * @returns True if the data is an array of IDevice
 * @example
 * if (isDeviceArray(data)) {
 * // data is an array of IDevice
 * }
 * */

export const isDeviceArray = (data: unknown): data is IDevice[] => {
  return isArray<IDevice>(data) && data.every(isDevice);
};