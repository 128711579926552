import { Outlier, Reading } from '../../../types';
import { FieldValue } from '../../shared/Field';
import { ChangeEvent, FC } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ManualInputOutlier } from './ManualInputOutlier';
import { ManualNitMessage } from './ManualNitMessage';
import {
  StyledFieldName,
  StyledInput,
  StyledTableCell,
  StyledTableCellWidth,
  StyledTableRow,
} from './styledComponents';

interface ManualInputReadingNitProps {
  readings: Reading[];
  handleOutlierChange: (outliers: Outlier[] | undefined, readingSourceName: string) => void;
  handleReadingChange: (event: ChangeEvent<HTMLInputElement>, name: string | undefined) => void;
  tabIndex?: number;
  isReadOnly?: boolean;
}

export const ManualInputReadingNit: FC<ManualInputReadingNitProps> = ({
  readings,
  handleOutlierChange,
  handleReadingChange,
  tabIndex,
  isReadOnly,
}) => {
  const defaultNitOutliers: Outlier[] = [
    {
      name: 'residual',
      value: 0,
    },
    {
      name: 'leverage',
      value: 0,
    },
    {
      name: 'standard deviation',
      value: 1,
    },
    {
      name: 'range',
      value: 0,
    },
  ];

  const handleOutlierValueChange = (event: React.ChangeEvent<HTMLInputElement>, reading: Reading) => {
    if (!reading.sourceName) {
      return;
    }

    const outliers: Outlier[] | undefined = event.target.checked ? defaultNitOutliers : undefined;

    handleOutlierChange(outliers, reading.sourceName);
  };

  const handleReadingValueChange = (event: React.ChangeEvent<HTMLInputElement>, reading: Reading) => {
    if (!reading.sourceName) {
      return;
    }
    handleReadingChange(event, reading.sourceName);
  };

  return (
    <>
      <ManualNitMessage />
      <TableContainer>
        <Table size="small" aria-label="Events table">
          <TableHead>
            <TableRow>
              <StyledTableCellWidth />
              <TableCell align="left">Värde</TableCell>
              <TableCell align="left">Outliers?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {readings?.map((requiredReading, index) => {
              return (
                <StyledTableRow key={requiredReading?.code}>
                  <StyledTableCell>
                    <StyledFieldName color="silver">
                      <span>{requiredReading?.code} </span>
                      <b>{requiredReading?.sourceName} </b>
                    </StyledFieldName>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <FieldValue color="silver">
                      <StyledInput
                        type="number"
                        onWheel={event => event.currentTarget.blur()}
                        value={requiredReading?.value !== undefined ? requiredReading.value : ''}
                        onChange={ev => handleReadingValueChange(ev, requiredReading)}
                        required
                        disabled={isReadOnly}
                        autoFocus={index === 0}
                        tabIndex={tabIndex}
                      />
                    </FieldValue>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <ManualInputOutlier
                      outliers={requiredReading?.outliers}
                      handleChange={x => handleOutlierValueChange(x, requiredReading)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
