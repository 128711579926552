import { ChangeEvent, FC } from 'react';
import { ReadingCode } from '../../constants/readingCode';
import { Outlier, Reading, Sample } from '../../types';
import { ManualInputReading } from './ManualInputReading';
import { DivTopPadding, H3TopPadding } from 'components/shared/DivWithTopPadding';
import { OtherAnalyzes } from 'components/OtherAnalyzes';
import { ManualNitMessage } from './nitDevice/ManualNitMessage';
import { ManualInputReadingNit } from './nitDevice/ManualInputReadingNit';
import { DeviceType } from 'constants/deviceTypes';

interface ManualAnalyzesProps {
  currentDeviceType?: string;
  sample?: Sample;
  currentReadings?: Reading[];
  tabIndexNumber: number;
  handleReadingValueChange: (event: ChangeEvent<HTMLInputElement>, name: string | undefined) => void;
  handleOutlierChange: (outliers: Outlier[] | undefined, readingSourceName: string) => void;
}

export const ManualAnalyzes: FC<ManualAnalyzesProps> = ({
  sample,
  currentReadings,
  tabIndexNumber,
  handleReadingValueChange,
  handleOutlierChange,
  currentDeviceType,
}) => {
  return (
    <>
      {sample && currentReadings && (
        <>
          <DivTopPadding>
            <ManualNitMessage deviceType={currentDeviceType} />
            {currentReadings?.length !== 0 ? <H3TopPadding>Manuella analyser:</H3TopPadding> : null}
            {currentDeviceType === DeviceType.NIT ? (
              <ManualInputReadingNit
                readings={currentReadings}
                handleOutlierChange={handleOutlierChange}
                handleReadingChange={handleReadingValueChange}
              />
            ) : (
              currentReadings?.map((requiredReading, index) => {
                return (
                  <ManualInputReading
                    arrayIndex={index}
                    tabIndex={index + tabIndexNumber}
                    key={index}
                    requiredReading={requiredReading}
                    handleReadingValueChange={handleReadingValueChange}
                    isReadOnly={requiredReading.code === ReadingCode.AF_WASTE}
                  />
                );
              })
            )}
            {sample && (
              <DivTopPadding>
                <OtherAnalyzes sample={sample} />
              </DivTopPadding>
            )}
          </DivTopPadding>
          <div
            id="focusguard-2"
            tabIndex={currentReadings ? currentReadings?.length + tabIndexNumber : Number.MAX_SAFE_INTEGER}
          ></div>
        </>
      )}
    </>
  );
};
