import { Analysis, Reading, InputValueProps } from '../../types';
import { ChangeEvent, useCallback, useContext, useEffect, useRef, useState, FocusEvent } from 'react';
import { LinearLoader } from '../shared/LinearLoader';
import { FlexContainer, FlexItem } from '../shared/FlexLayout';
import { Card, CardBody, CardHeader } from '../shared/Card';
import { OtherAnalyzes } from '../OtherAnalyzes';
import { ExtraAnalyzes } from '../ExtraAnalyzes/ExtraAnalyzes';
import { SampleErrorMessage } from '../shared/ErrorMessage';
import { useParams } from 'react-router-dom';
import { ExtraInfo } from '../ExtraInfo';
import { ReadingCode } from '../../constants/readingCode';
import { Overlay } from './styles/styles';
import { InformationContainer } from './components/InformationContainer';
import { NitCardButtonPanel } from './components/NitCardButtonPanel';
import { ErrorContainer } from '../ErrorContainer';
import { BasicInputContainer } from 'components/shared/inputs/BasicInputContainer';
import { NitValueReadings } from './components/NitValueReadings';
import { TabOrder } from './components/TabOrder';
import { BannerMessageContainer } from '../BannerMessages/BannerMessageContainer';
import { getLatestNitReadings } from 'utils/readingUtils';
import useDialog from 'hooks/useDialog';
import { RESET_VALUES_DIALOG_BODY, RESET_VALUES_DIALOG_TITLE } from 'constants/resetValuesStrings';
import { useGetSample } from 'hooks/useGetSample';
import { EXISTING_ANALYSIS_DIALOG_TITLE } from 'constants/existingAnalysisDialogTitle';
import { generateExistingAnalysisDialogString } from 'functions/generateExistingAnalysisDialogString';
import { DeviceType } from 'constants/deviceTypes';
import AnalyzesContext from 'contexts/AnalyzesContext';
import { isApproveDisabled } from 'functions/isApproveDisabled';
import useSubmitAnalyzes from 'hooks/useSubmitAnalyzes';
import useNavigationBlocker from 'hooks/useNavigationBlocker';
import { DivTopPadding } from 'components/shared/DivWithTopPadding';

interface NitAnalysisCardProps {
  analysis?: Analysis;
  clearAnalysis?: () => void;
  onLock?: (locked: boolean) => void;
  locked?: boolean;
  onLockReading?: (reading: Reading, locked: boolean) => void;
}

type LocationType = {
  location: string;
};

export const NitAnalysisCard = ({ analysis, clearAnalysis, onLock, locked, onLockReading }: NitAnalysisCardProps) => {
  const defaultInputValues: InputValueProps = {
    articleNumber: '',
    sampleId: '',
    signature: '',
  };

  const [inputValue, setInputValue] = useState({ ...defaultInputValues });
  const { sample, resetSampleValues, getSample, sampleMessages, triedToFetchSample, loading } = useGetSample();
  const { location } = useParams<LocationType>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [focus, setFocus] = useState('signature');
  const [showM3ConnectionError, setShowM3ConnectionError] = useState<boolean>();
  const { setIncomingAnalyzes, resetAnalyzesState, setNewExtraAnalyzes, newExtraAnalyzes, text, oldText, setOldText, setText } = useContext(AnalyzesContext);
  const { errorMessages, isSaving, setErrorMessages, saveAnalyzes } = useSubmitAnalyzes();

  const navigationBlockingConditions = [
    analysis !== undefined,
    newExtraAnalyzes.length !== 0,
    text !== oldText
  ]
  useNavigationBlocker(navigationBlockingConditions);

  useEffect(
    () => {
      const sampleText = sample?.text ?? '';
      setInputValue({ ...inputValue, articleNumber: sample?.articleNumber ?? '' });
      setOldText(sampleText);
      setText(sampleText);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sample]
  );

  const buttonPanelRef = useRef<HTMLFormElement>(null);
  const basicInputRef = useRef<HTMLDivElement>(null);
  const { showDialog } = useDialog();

  const resetValues = () => {
    resetAnalyzesState();
    clearAnalysis && clearAnalysis();
    setInputValue({ ...defaultInputValues });
    setNewExtraAnalyzes([]);
    setShowM3ConnectionError(false);
    resetSampleValues();
    setOldText('');
    setText('');
    setErrorMessages([]);
  };

  const handleBlur = async () => {
    if (inputValue?.sampleId === sample?.id) {
      return;
    }
    await getSample(inputValue?.sampleId);
  };

  const handleSignatureChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue({ ...inputValue, [event.target.name]: event.target.value });
  };

  const handleIDChange = (event: ChangeEvent<HTMLInputElement>) => {
    resetSampleValues();
    setInputValue({ ...inputValue, [event.target.name]: event.target.value.trim() });
  };

  const handleSubmit = async () => {
    setFocus('signature');
    if (
      analysis &&
      sample?.readings &&
      sample.readings.some(
        reading =>
          reading.location === analysis?.location &&
          (reading.code === ReadingCode.NIT_WATER ||
            reading.code === ReadingCode.NIT_GLUTEN ||
            reading.code === ReadingCode.NIT_PROTEIN ||
            reading.code === ReadingCode.NIT_STARCH_DM ||
            reading.code === ReadingCode.NIT_SPECIFIC_WEIGHT)
      )
    ) {
      showDialog(
        EXISTING_ANALYSIS_DIALOG_TITLE,
        generateExistingAnalysisDialogString(sample.id, location, DeviceType.NIT),
        submitAnalyzes,
        false
      );
    } else {
      submitAnalyzes();
    }
  };

  const submitAnalyzes = async (): Promise<void> => {
    const analyzesToSave: Analysis[] = analysis ? [...newExtraAnalyzes, analysis] : [...newExtraAnalyzes];
    const saveIsSuccess = await saveAnalyzes(analyzesToSave, inputValue, location, text, oldText, sample);

    if (saveIsSuccess) {
      resetValues();
      setFocus('signature');
      setIncomingAnalyzes([]);
    }
  };

  const handleClear = () => {
    setFocus('signature');
    showDialog(RESET_VALUES_DIALOG_TITLE, RESET_VALUES_DIALOG_BODY, resetValues, true);
  };

  const handleLockReading = (reading: Reading, isLocked: boolean) => {
    if (onLockReading) {
      onLockReading(reading, isLocked);
    }
  };

  const isCalibrationMatching = useCallback(() => {
    if (!showM3ConnectionError) {
      if (
        analysis?.calibration &&
        sample?.grainType &&
        analysis.calibration.startsWith(sample.grainType)
      ) {
        return true;
      }
    }
    return false;
  }, [analysis, sample, showM3ConnectionError]);

  const getNitValueReadingsSubtitle = (): string | undefined => {
    const nitValues = getLatestNitReadings(sample?.readings);

    if (!analysis?.readings && !nitValues) {
      return undefined;
    }

    if (sample && nitValues?.length === 0) {
      return ReadingCode.NO_DATA;
    }

    return ReadingCode.EMPTY_STRING;
  };

  const enableApproveConditions: boolean[] = [analysis !== undefined];
  const disableApproveConditions: boolean[] = [analysis !== undefined && !isCalibrationMatching()];
  const isApproveButtonDisabled = isApproveDisabled({
    sample,
    newExtraAnalyzes: newExtraAnalyzes,
    inputValue,
    text,
    oldText,
    isSaving,
    enableConditions: enableApproveConditions,
    disableConditions: disableApproveConditions,
  });

  useEffect(() => {
    if (!isApproveButtonDisabled && !showModal) {
      if (!showModal) {
        setFocus('information');
        const timer = setTimeout(() => {
          setFocus('approve');
        }, 100);
        return () => clearTimeout(timer);
      } else {
        setFocus('approve');
      }
    }
  }, [isApproveButtonDisabled, setFocus, showModal]);

  const informationButtonDisabled = (): boolean => {
    if (!sample || sample.completed) {
      return true;
    }
    return false;
  };

  const lockButtonDisabled = (): boolean => {
    if (!analysis) {
      return true;
    }
    return false;
  };

  const onChildFocus = (event: FocusEvent<HTMLButtonElement | HTMLInputElement>, id: string) => {
    setFocus(id);
    event.preventDefault();
  };

  return (
    <Card>
      {showModal && (
        <ExtraInfo
          sample={sample}
          newExtraAnalyzes={newExtraAnalyzes}
          setNewExtraAnalyzes={setNewExtraAnalyzes}
          location={location}
          onClose={() => setShowModal(false)}
          setText={setText}
          sampleText={text}
        />
      )}
      {isSaving ? (
        <Overlay>
          <LinearLoader />
        </Overlay>
      ) : null}
      {loading ? <LinearLoader /> : null}
      <TabOrder
        focus={focus}
        setFocus={setFocus}
        refs={{
          buttons: buttonPanelRef,
          input: basicInputRef,
        }}
        hidden={showModal}
      >
        <CardHeader>
          <BannerMessageContainer sampleMessages={sampleMessages} />
          <SampleErrorMessage
            sample={sample}
            sampleId={inputValue?.sampleId}
            triedToFetchSample={triedToFetchSample}
            getSample={getSample}
          />
          <ErrorContainer
            analysis={analysis}
            calibration={isCalibrationMatching()}
            connectionError={showM3ConnectionError}
            sample={sample}
            analysisErrors={errorMessages}
          />
          <FlexContainer>
            <BasicInputContainer
              width={50}
              inputValue={inputValue}
              handleSignatureChange={handleSignatureChange}
              handleIDChange={handleIDChange}
              onBlurSampleId={handleBlur}
              focus={focus}
              onFocus={onChildFocus}
              ref={basicInputRef}
            />
            <InformationContainer width={50} spacing={15} analysis={analysis} sample={sample} />
          </FlexContainer>
        </CardHeader>
        <CardBody>
          <FlexItem spacing={15}>
            <NitCardButtonPanel
              onLock={onLock}
              onLockReading={onLockReading}
              locked={locked}
              saving={isSaving}
              handleSubmit={handleSubmit}
              showInformation={setShowModal}
              handleClear={handleClear}
              approveDisabled={isApproveButtonDisabled}
              informationDisabled={informationButtonDisabled}
              informationAvailble={!!text}
              lockDisabled={lockButtonDisabled}
              focus={focus}
              onFocus={onChildFocus}
              ref={buttonPanelRef}
            />
            <NitValueReadings
              title="Värde från NIT:"
              sample={sample}
              readings={analysis?.readings}
              condition={analysis?.readings !== undefined}
              handle={handleLockReading}
              lock={locked}
            />
            <NitValueReadings
              title="Värde från tidigare NIT analys:"
              subTitle={getNitValueReadingsSubtitle()}
              readings={getLatestNitReadings(sample?.readings)}
              handle={handleLockReading}
              condition={!analysis?.readings && getLatestNitReadings(sample?.readings) !== undefined}
              lock={locked}
            />
            {sample && (
              <DivTopPadding>
                <OtherAnalyzes sample={sample} />
              </DivTopPadding>
            )}
            <ExtraAnalyzes sample={sample} newExtraAnalyzes={newExtraAnalyzes} setNewExtraAnalyzes={setNewExtraAnalyzes} />
          </FlexItem>
        </CardBody>
      </TabOrder>
    </Card>
  );
};
