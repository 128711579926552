import { Reading, Sample } from 'types';

export const useOutliersUtil = () => {
  /**
   * Check if the reading has outliers and if the reading is required
   * @param reading
   * @param sample - optional, if provided, it will check if the reading is required in the sample
   * @returns boolean - true if the reading has outliers and is required, false otherwise
   */
  const showErrorForOutlier = (reading: Reading | undefined, sample?: Sample) => {
    const sampleReading = sample?.readings?.find(r => r.code === reading?.code);
    const isRequired = reading?.required || sampleReading?.required;

    // If the reading is not required, we don't need to show the error
    if (!isRequired) {
      return false;
    }

    const hasOutliers = !!reading?.outliers?.filter(outlier => Math.round(outlier.value) > 0).length;

    // If the reading has outliers, we need to show the error
    if (hasOutliers) {
      return true;
    }

    // If the reading doesn't have outliers, we don't need to show the error
    return false;
  };

  return { showErrorForOutlier };
};
