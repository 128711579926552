import { ChangeEvent, FC } from 'react';
import { Outlier } from 'types';

interface ManualInputOutlierProps {
  outliers: Outlier[] | undefined;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isReadOnly?: boolean;
}

export const ManualInputOutlier: FC<ManualInputOutlierProps> = ({ outliers, handleChange, isReadOnly }) => {
  const outliersIsEmpty = !outliers;

  return (
    <div className="lm__checkbox lm__tick">
      <input type="checkbox" checked={!outliersIsEmpty} disabled={isReadOnly} onChange={handleChange} />
      <label></label>
    </div>
  );
};
