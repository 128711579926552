import { Reading } from '../../types';
import { Field, FieldName, FieldValue } from '../shared/Field';
import { ChangeEvent, FC } from 'react';

interface ManualInputReadingProps {
  requiredReading: Reading | undefined;
  handleReadingValueChange: (event: ChangeEvent<HTMLInputElement>, name: string | undefined) => void;
  arrayIndex: number;
  tabIndex: number;
  isReadOnly: boolean;
}

export const ManualInputReading: FC<ManualInputReadingProps> = ({
  requiredReading,
  handleReadingValueChange,
  arrayIndex,
  tabIndex,
  isReadOnly,
}) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleReadingValueChange(event, requiredReading?.sourceName);
  };

  const autoFocus = arrayIndex === 0;
  return (
    <Field>
      <FieldName color="silver">
        <span>{requiredReading?.code} </span>
        <b>{requiredReading?.sourceName} </b>
      </FieldName>
      <FieldValue color="silver">
        <input
          type="number"
          onWheel={event => event.currentTarget.blur()}
          value={requiredReading?.value !== undefined ? requiredReading.value : ''}
          onChange={handleChange}
          required
          autoFocus={autoFocus}
          tabIndex={tabIndex}
          readOnly={isReadOnly}
          disabled={isReadOnly}
          style={{ height: '10px', width: '75px', maxWidth: 'fit-content' }}
        />
      </FieldValue>
    </Field>
  );
};
