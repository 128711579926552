import { styled as MUIStyled } from '@mui/system';
import styled from '@emotion/styled';
import { TableCell, TableRow } from '@mui/material';
import { FieldName } from 'components/shared/Field';

export const StyledTableRow = MUIStyled(TableRow)(() => ({
  backgroundColor: 'transparent',
  '&:nth-of-type(odd)': {
    backgroundColor: 'transparent',
  },
  '&:nth-of-type(even)': {
    backgroundColor: 'transparent',
  },
  '&:last-child td, &:last-child th': { border: 0 },
}));

export const StyledTableCell = MUIStyled(TableCell)(() => ({
  padding: '0px',
}));

/**
 * TableCell with width 100%
 */
export const StyledTableCellWidth = MUIStyled(TableCell)`
  width: 100%;
`;

export const StyledInput = styled.input`
  height: 28px;
  width: 75px;
  max-width: fit-content;
`;

export const StyledFieldName = styled(FieldName)`
  font-size: 16px;
`;