import { FC } from "react";
import { compareBy } from "../../../utils/sortUtils";
import { ReadingItem } from "../../Reading";
import { FlexItem } from "../../shared/FlexLayout";
import { NitValueReadingsProps } from "../models/Props";


export const NitValueReadings: FC<NitValueReadingsProps> =  ({
    title,
    subTitle,
    readings,
    condition,
    handle,
    lock,
    sample
}) =>{
    return (
      <>
        {condition ? (
          <>
            <FlexItem>
              <h3>{title}</h3>
              {subTitle}
            </FlexItem>
            {readings?.sort(compareBy((x) => x['order']))
              .map((reading) => (
                <ReadingItem
                  sample={sample}
                  key={reading.code ?? reading.sourceName}
                  reading={reading}
                  locked={reading.locked}
                  onLock={
                    (lock && ((locked) => handle(reading, locked))) || undefined
                  }
                />
              ))}
          </>) : null}
      </>
    );
}