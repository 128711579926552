import { LmBlock } from '../lm2/LmBlock';
import { ManualAnalysisCard } from './ManualAnalysisCard';

export const ManualAnalysis = () => {
  return (
      <LmBlock>
        <ManualAnalysisCard />
      </LmBlock>
  );
};
